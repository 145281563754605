const callMe = () => {
    window.open('tel:+34 680 49 07 55')
}

const emailMe = () => {
    window.open('mailto:nico.reformas.integrales@gmail.com?subject=Presupuesto')
}

const instagram = 'https://www.instagram.com/nico_construcionessl/'

export { callMe, emailMe, instagram }