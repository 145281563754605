import React from 'react'
// assets
import backgroundHeader from '../../assets/bg.jpg'
// utils
import { isMobile } from '../../utils/isMobile'
import { callMe, emailMe } from '../../utils/functions'
// css
import './header.css'

function Header() {

    return (
        <div
            className='header' 
            style={{
                background: `url(${backgroundHeader})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat !important'
            }}
        >
            <h1>EN NICO CONSTRUCCIONES Y REFORMAS S.L,</h1>
            <p>CONSTRUIMOS TU SUEÑO PASO A PASO.</p>

            <div className='btn' onClick={ isMobile ? callMe : emailMe}>
                <p>Contáctanos</p>
            </div>
            
            <div id='separation'/>
        </div>
    )
}

export default Header