import React, { useEffect } from 'react'
// css
import './legal.css'

function Legal() {

  const url = 'https://nicoreformasintegrales.com/'
  const name_short = 'nicoreformasintegrales'

  const name = 'Victor Manuel Martinez Jurado'
  const DNI = '51114808E'
  const address = 'Calle Pablo Tejera, 1'
  const phone = '680 49 07 55'
  const email = 'nico.reformas.integrales@gmail.com'

  useEffect(() => {
    const scrollTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
    
    scrollTop()
    
    clearTimeout(scrollTop)
  }, [])

  return (
    <div className='legal'>
      <div className='legal_safe_area'>
        <div id='separation'/>
        <h1>AVISO LEGAL Y CONDICIONES GENERALES DE USO DEL SITIO WEB</h1>
        <p id='link'>{url}</p>

        <h1>I. INFORMACIÓN GENERAL </h1>
        <p>
          En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:<br/>
          La titularidad de este sitio web, {url} , (en adelante, Sitio Web) la ostenta:<br/> 
          {name}, con NIF: {DNI}, y cuyos datos de contacto son:<br/>
          Dirección: <br/>
          {address}<br/>
          Teléfono de contacto: {phone}<br/>
          Email de contacto: {email}<br/>
        </p>

        <h1>II. TÉRMINOS Y CONDICIONES GENERALES DE USO </h1>
        <p>
          <span>El objeto de las condiciones: El Sitio Web<br/></span>
          <div id='separation'/>
          El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante, Servicios).<br/>
          {name_short} se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento  pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.<br/>
          El acceso al Sitio Web por el Usuario tiene carácter libre y, por regla general, es gratuito sin que el Usuario tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo relativo al coste de conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado el Usuario.<br/>
          La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse mediante la suscripción o registro previo del Usuario.<br/>

          <div id='separation'/>

          <span>El Usuario<br/></span>

          <div id='separation'/>

          El acceso, la navegación y uso del Sitio Web, así como por los espacios habilitados para interactuar entre los Usuarios, y el Usuario y {name_short}, como los comentarios y/o espacios de blogging, confiere la condición de Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web.<br/>
          El Sitio Web de {name_short} proporciona gran diversidad de información, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:<br/>
          <div id='separation'/>

          ⬤ Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por {name_short} sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web.<br/>

          <div id='separation'/>

          ⬤ La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por {name_short} para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a {name_short}  acerca de cualquier hecho que permita el uso indebido de la información registrada en dichos formularios, tales como, pero no sólo, el robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.<br/>

          <div id='separation'/>

          {name_short}  se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, spamming, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación.<br/>

          <div id='separation'/>

          En cualquier caso, {name_short} no será responsable de las opiniones vertidas por los Usuarios a través de comentarios u otras herramientas de blogging o de participación que pueda haber.<br/>
          El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre {name_short} y el Usuario.<br/>
          El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de {name_short} no se dirige a menores de edad. {name_short} declina cualquier responsabilidad por el incumplimiento de este requisito.<br/>
          El Sitio Web está dirigido principalmente a Usuarios residentes en España.<br/>

          <div id='separation'/>

          {name_short} no asegura que el Sitio Web cumpla con legislaciones de otros países, ya sea total o parcialmente. Si el Usuario reside o tiene su domiciliado en otro lugar y decide acceder y/o navegar en el Sitio Web lo hará bajo su propia responsabilidad, deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo {name_short} responsabilidad alguna que se pueda derivar de dicho acceso.<br/>
        </p>

        <h1>III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h1>

        <p>
          {name_short} no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios.<br/>

          <div id='separation'/>

          {name_short} hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.<br/>

          <div id='separation'/>

          Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de este Sitio Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario. En ningún caso {name_short} será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los sistemas informáticos o los provocados por la introducción de virus.
        </p>

        <h1>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h1>
        <p>
          {name_short} se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos.<br/>

          <div id='separation'/>

          <span>Leyes que incorpora esta política de privacidad<br/></span>
          <div id='separation'/>
          
          Esta política de privacidad está adaptada a la normativa española y europea vigente en materia de protección de datos personales en internet. En concreto, la misma respeta las siguientes normas:<br/>

          <div id='separation'/>

          ⬤ El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).<br/>

          <div id='separation'/>

          ⬤ La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD).<br/>

          <div id='separation'/>

          ⬤ El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (RDLOPD).<br/>

          <div id='separation'/>

          ⬤ La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE).<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Identidad del responsable del tratamiento de los datos personales<br/></span>

          El responsable del tratamiento de los datos personales recogidos en {name_short} es: Victor Manuel Martinez Jurado, con NIF: 51114808E (en adelante, también Responsable del tratamiento). Sus datos de contacto son los siguientes:<br/>

          <div id='separation'/>

          Dirección: <br/>
          {address}<br/>
          Teléfono de contacto: {phone}<br/>
          Email de contacto: {email}<br/>

          <div id='separation'/>

          <span>Registro de Datos de Carácter Personal<br/></span>

          En cumplimiento de lo establecido en el RGPD y la LOPD-GDD, le informamos que los datos personales recabados por {name_short} mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestro ficheros con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre {name_short} y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este rellene, o para atender una solicitud o consulta del mismo. Asimismo, de conformidad con lo previsto en el RGPD y la LOPD-GDD, salvo que sea de aplicación la excepción prevista en el artículo 30.5 del RGPD, se mantiene un registro de actividades de tratamiento que especifica, según sus finalidades, las actividades de tratamiento llevadas a cabo y las demás circunstancias establecidas en el RGPD.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Principios aplicables al tratamiento de los datos personales<br/></span>
          <div id='separation'/>
          El tratamiento de los datos personales del Usuario se someterá a los siguientes principios recogidos en el artículo 5 del RGPD:<br/>
          <div id='separation'/>

          ⬤ Principio de licitud, lealtad y transparencia: se requerirá en todo momento el consentimiento del Usuario previa información completamente transparente de los fines para los cuales se recogen los datos personales.<br/>

          <div id='separation'/>

          ⬤ Principio de limitación de la finalidad: los datos personales serán recogidos con fines determinados, explícitos y legítimos.<br/>

          <div id='separation'/>

          ⬤ Principio de minimización de datos: los datos personales recogidos serán únicamente los estrictamente necesarios en relación con los fines para los que son tratados.<br/>

          <div id='separation'/>

          ⬤ Principio de exactitud: los datos personales deben ser exactos y estar siempre actualizados.<br/>

          <div id='separation'/>

          ⬤ Principio de limitación del plazo de conservación: los datos personales solo serán mantenidos de forma que se permita la identificación del Usuario durante el tiempo necesario para los fines de su tratamiento.<br/>

          <div id='separation'/>

          ⬤ Principio de integridad y confidencialidad: los datos personales serán tratados de manera que se garantice su seguridad y confidencialidad.<br/>

          <div id='separation'/>

          ⬤ Principio de responsabilidad proactiva: el Responsable del tratamiento será responsable de asegurar que los principios anteriores se cumplen.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Categorías de datos personales<br/></span>
          <div id='separation'/>
          Las categorías de datos que se tratan en {name_short} son únicamente datos identificativos. En ningún caso, se tratan categorías especiales de datos personales en el sentido del artículo 9 del RGPD.<br/>
          <div id='separation'/>
          Base legal para el tratamiento de los datos personales<br/>
          La base legal para el tratamiento de los datos personales es el consentimiento.<br/>

          <div id='separation'/>

          {name_short} se compromete a recabar el consentimiento expreso y verificable del Usuario para el tratamiento de sus datos personales para uno o varios fines específicos.<br/>

          <div id='separation'/>

          El Usuario tendrá derecho a retirar su consentimiento en cualquier momento. Será tan fácil retirar el consentimiento como darlo. Como regla general, la retirada del consentimiento no condicionará el uso del Sitio Web.<br/>

          <div id='separation'/>

          En las ocasiones en las que el Usuario deba o pueda facilitar sus datos a través de formularios para realizar consultas, solicitar información o por motivos relacionados con el contenido del Sitio Web, se le informará en caso de que la cumplimentación de alguno de ellos sea obligatoria debido a que los mismos sean imprescindibles para el correcto desarrollo de la operación realizada.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Fines del tratamiento a que se destinan los datos personales<br/></span>
          <div id='separation'/>

          Los datos personales son recabados y gestionados por {name_short} con la finalidad de poder facilitar, agilizar y cumplir los compromisos establecidos entre el Sitio Web y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este último rellene o para atender una solicitud o consulta.<br/>

          <div id='separation'/>

          Igualmente, los datos podrán ser utilizados con una finalidad comercial de personalización, operativa y estadística, y actividades propias del objeto social de {name_short}, así como para la extracción, almacenamiento de datos y estudios de marketing para adecuar el Contenido ofertado al Usuario, así como mejorar la calidad, funcionamiento y navegación por el Sitio Web.<br/>

          <div id='separation'/>

          En el momento en que se obtengan los datos personales, se informará al Usuario acerca del fin o fines específicos del tratamiento a que se destinarán los datos personales; es decir, del uso o usos que se dará a la información recopilada.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Períodos de retención de los datos personales<br/></span>
          <div id='separation'/>
          Los datos personales solo serán retenidos durante el tiempo mínimo necesario para los fines de su tratamiento y, en todo caso, únicamente durante el siguiente plazo:<br/>
          meses, o hasta que el Usuario solicite su supresión.

          <div id='separation'/>

          En el momento en que se obtengan los datos personales, se informará al Usuario acerca del plazo durante el cual se conservarán los datos personales o, cuando eso no sea posible, los criterios utilizados para determinar este plazo.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Destinatarios de los datos personales<br/></span>
          <div id='separation'/>
          Los datos personales del Usuario serán compartidos con los siguientes destinatarios o categorías de destinatarios:<br/>
          <div id='separation'/>
          {name_short}.com<br/>
          <div id='separation'/>
          En caso de que el Responsable del tratamiento tenga la intención de transferir datos personales a un tercer país u organización internacional, en el momento en que se obtengan los datos personales, se informará al Usuario acerca del tercer país u organización internacional al cual se tiene la intención de transferir los datos, así como de la existencia o ausencia de una decisión de adecuación de la Comisión.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Datos personales de menores de edad<br/></span>
          <div id='separation'/>

          Respetando lo establecido en los artículos 8 del RGPD y 13 del RDLOPD, solo los mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por {name_short}. Si se trata de un menor de 14 años, será necesario el consentimiento de los padres o tutores para el tratamiento, y este solo se considerará lícito en la medida en la que los mismos lo hayan autorizado.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Secreto y seguridad de los datos personales<br/></span>
          <div id='separation'/>
          {name_short} se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.<br/>
          <div id='separation'/>
          Sin embargo, debido a que {name_short} no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, el Responsable del tratamiento se compromete a comunicar al Usuario sin dilación indebida cuando ocurra una violación de la seguridad de los datos personales que sea probable que entrañe un alto riesgo para los derechos y libertades de las personas físicas. Siguiendo lo establecido en el artículo 4 del RGPD, se entiende por violación de la seguridad de los datos personales toda violación de la seguridad que ocasione la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.<br/>
          <div id='separation'/>
          Los datos personales serán tratados como confidenciales por el Responsable del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligación legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados, y toda persona a la cual le haga accesible la información.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Derechos derivados del tratamiento de los datos personales<br/></span>
          <div id='separation'/>
          El Usuario tiene sobre {name_short} y podrá, por tanto, ejercer frente al Responsable del tratamiento los siguientes derechos reconocidos en el RGPD:<br/>
          <div id='separation'/>

          ⬤ Derecho de acceso: Es el derecho del Usuario a obtener confirmación de si {name_short} está tratando o no sus datos personales y, en caso afirmativo, obtener información sobre sus datos concretos de carácter personal y del tratamiento que {name_short} haya realizado o realice, así como, entre otra, de la información disponible sobre el origen de dichos datos y los destinatarios de las comunicaciones realizadas o previstas de los mismos.<br/>

          <div id='separation'/>

          ⬤ Derecho de rectificación: Es el derecho del Usuario a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.<br/>

          <div id='separation'/>

          ⬤ Derecho de supresión ("el derecho al olvido"): Es el derecho del Usuario, siempre que la legislación vigente no establezca lo contrario, a obtener la supresión de sus datos personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; el Usuario haya retirado su consentimiento al tratamiento y este no cuente con otra base legal; el Usuario se oponga al tratamiento y no exista otro motivo legítimo para continuar con el mismo; los datos personales hayan sido tratados ilícitamente; los datos personales deban suprimirse en cumplimiento de una obligación legal; o los datos personales hayan sido obtenidos producto de una oferta directa de servicios de la sociedad de la información a un menor de 14 años. Además de suprimir los datos, el Responsable del tratamiento, teniendo en cuenta la tecnología disponible y el coste de su aplicación, deberá adoptar medidas razonables para informar a los responsables que estén tratando los datos personales de la solicitud del interesado de supresión de cualquier enlace a esos datos personales.<br/>

          <div id='separation'/>

          ⬤ Derecho a la limitación del tratamiento: Es el derecho del Usuario a limitar el tratamiento de sus datos personales. El Usuario tiene derecho a obtener la limitación del tratamiento cuando impugne la exactitud de sus datos personales; el tratamiento sea ilícito; el Responsable del tratamiento ya no necesite los datos personales, pero el Usuario lo necesite para hacer reclamaciones; y cuando el Usuario se haya opuesto al tratamiento.<br/>

          <div id='separation'/>

          ⬤ Derecho a la portabilidad de los datos: En caso de que el tratamiento se efectúe por medios automatizados, el Usuario tendrá derecho a recibir del Responsable del tratamiento sus datos personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea técnicamente posible, el Responsable del tratamiento transmitirá directamente los datos a ese otro responsable.<br/>

          <div id='separation'/>

          ⬤ Derecho de oposición: Es el derecho del Usuario a que no se lleve a cabo el tratamiento de sus datos de carácter personal o se cese el tratamiento de los mismos por parte de {name_short}.<br/>

          <div id='separation'/>

          ⬤ Derecho a no ser a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles: Es el derecho del Usuario a no ser objeto de una decisión individualizada basada únicamente en el tratamiento automatizado de sus datos personales, incluida la elaboración de perfiles, existente salvo que la legislación vigente establezca lo contrario.<br/>

          <div id='separation'/>
          <div id='separation'/>

          Así pues, el Usuario podrá ejercitar sus derechos mediante comunicación escrita dirigida al Responsable del tratamiento con la referencia "RGPD-https://{name_short}.com/", especificando:<br/>
          <div id='separation'/>

          ⬤ Nombre, apellidos del Usuario y copia del DNI. En los casos en que se admita la representación, será también necesaria la identificación por el mismo medio de la persona que representa al Usuario, así como el documento acreditativo de la representación. La fotocopia del DNI podrá ser sustituida, por cualquier otro medio válido en derecho que acredite la identidad.<br/>

          <div id='separation'/>

          ⬤ Petición con los motivos específicos de la solicitud o información a la que se quiere acceder.<br/>

          <div id='separation'/>

          ⬤ Domicilio a efecto de notificaciones.<br/>

          <div id='separation'/>

          ⬤ Fecha y firma del solicitante.<br/>

          <div id='separation'/>

          ⬤ Todo documento que acredite la petición que formula.<br/>

          <div id='separation'/>
          <div id='separation'/>

          Esta solicitud y todo otro documento adjunto podrá enviarse a la siguiente dirección y/o correo electrónico:<br/>
          <div id='separation'/>

          Dirección postal: <br/>
          {address}<br/>
          Email de contacto: {email}<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Enlaces a sitios web de terceros<br/></span>
          <div id='separation'/>
          El Sitio Web puede incluir hipervínculos o enlaces que permiten acceder a páginas web de terceros distintos de {name_short}, y que por tanto no son operados por {name_short}. Los titulares de dichos sitios web dispondrán de sus propias políticas de protección de datos, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias prácticas de privacidad.<br/>

          <div id='separation'/>
          <div id='separation'/>

          <span>Reclamaciones ante la autoridad de control<br/></span>
          <div id='separation'/>

          En caso de que el Usuario considere que existe un problema o infracción de la normativa vigente en la forma en la que se están tratando sus datos personales, tendrá derecho a la tutela judicial efectiva y a presentar una reclamación ante una autoridad de control, en particular, en el Estado en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracción. En el caso de España, la autoridad de control es la Agencia Española de Protección de Datos (http://www.agpd.es).<br/>

          <div id='separation'/>
          <div id='separation'/>

          Aceptación y cambios en esta política de privacidad<br/>
          <div id='separation'/>

          Es necesario que el Usuario haya leído y esté conforme con las condiciones sobre la protección de datos de carácter personal contenidas en esta Política de Privacidad, así como que acepte el tratamiento de sus datos personales para que el Responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del Sitio Web implicará la aceptación de la Política de Privacidad del mismo.<br/>

          <div id='separation'/>

          {name_short} se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos. Los cambios o actualizaciones de esta Política de Privacidad  serán notificados de forma explícita al Usuario.<br/>

          <div id='separation'/>

          Esta Política de Privacidad fue actualizada el día 22 de febrero 2024 para adaptarse al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD)<br/>

        </p>

        <h1>V. POLÍTICA DE ENLACES </h1>
        <p>
          Se informa que el Sitio Web de {name_short} pone o puede poner a disposición de los Usuarios medios de enlace (como, entre otros, links, banners, botones), directorios y motores de búsqueda que permiten a los Usuarios acceder a sitios web pertenecientes y/o gestionados por terceros.<br/>

          <div id='separation'/>

          La instalación de estos enlaces, directorios y motores de búsqueda en el Sitio Web tiene por objeto facilitar a los Usuarios la búsqueda de y acceso a la información disponible en Internet, sin que pueda considerarse una sugerencia, recomendación o invitación para la visita de los mismos.<br/>

          <div id='separation'/>

          {name_short} no ofrece ni comercializa por sí ni por medio de terceros los productos y/o servicios disponibles en dichos sitios enlazados.<br/>

          <div id='separation'/>

          Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.<br/>

          <div id='separation'/>

          {name_short} en ningún caso revisará o controlará el contenido de otros sitios web, así como tampoco aprueba, examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro material existente en los referidos sitios enlazados.<br/>

          <div id='separation'/>

          {name_short} no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso, uso, calidad o licitud de los contenidos, comunicaciones, opiniones, productos y servicios de los sitios web no gestionados por {name_short} y que sean enlazados en este Sitio Web.<br/>

          <div id='separation'/>

          El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web de {name_short} deberá saber que:<br/>

          <div id='separation'/>

          No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorización expresa de {name_short}.<br/>

          <div id='separation'/>

          No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de {name_short}, ni sobre los Contenidos y/o Servicios del mismo.<br/>

          <div id='separation'/>

          A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace no contendrá ningún elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jurídico español, salvo autorización expresa de {name_short}.<br/>

          <div id='separation'/>

          El establecimiento del hipervínculo no implicará la existencia de relaciones entre {name_short} y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptación de {name_short} de los contenidos, servicios y/o actividades ofrecidos en dicho sitio web, y viceversa.<br/>
        </p>

        <h1>VI. PROPIEDAD INTELECTUAL E INDUSTRIAL</h1>
        <p>
          {name_short} por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.<br/>

          <div id='separation'/>

          Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de {name_short}.<br/>

          <div id='separation'/>

          En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a {name_short} a través de los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones Generales de Uso.<br/>

          <div id='separation'/>
          <div id='separation'/>
        </p>

        <h1>VII. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h1>
        <p>
          {name_short} se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.<br/>
          <div id='separation'/>

          La relación entre el Usuario y {name_short} se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de estas Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y tribunales que correspondan conforme a derecho.<br/>

          <div id='separation'/>

          Ultima modificación: 22 de febrero 2024<br/>

          <div id='separation'/>
        </p>
      </div>
    </div>
  )
}

export default Legal