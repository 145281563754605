import React from 'react'
// assets
import starIcon from '../../assets/icons/star.png'
import googleIcon from '../../assets/icons/google.png'
// css
import './comments.css'
import { isMobile } from '../../utils/isMobile'

function Comments() {
  return (
    <div className='comments'>

        <div className='comments_safe_area'>
            <h1>LO QUE OPINAN NUESTROS CLIENTES</h1>
        </div>

        <div className='comments_safe_area'>
            <div className='comment'>
                <div className='info'>
                    <div className='info_'>
                        <h1 id='profile'>A</h1>
                        <h1>Antonio Herrador</h1>
                    </div>
                    <img src={googleIcon} alt='google'/>
                </div>

                <div className='stars'>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                </div>

                <p>
                    Recientemente he hecho la reforma de mi casa con Nico y su equipo  y debo decir que la experiencia ha sido excepcional.
                    Desde el principio, Nico demostró una gran profesionalidad, asegurándose de comprender mis necesidades y expectativas para el proyecto.
                </p>
            </div>

            <div className='comment'>
                <div className='info'>
                    <div className='info_'>
                        <h1 id='profile'>L</h1>
                        <h1>Laura Jimenez Moreno</h1>
                    </div>
                    <img src={googleIcon} alt='google'/>
                </div>

                <div className='stars'>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                </div>

                <p>
                    Contratamos a Nico para pintar la fachada de una casa unifamiliar e instalar unos canalones. Tanto él como sus chicos fueron extremadamente profesionales en todo momento. El trabajo quedó impecable y cumplieron con los tiempos acordados. Un gusto, los recomiendo sin duda.
                </p>
            </div>

            <div className='comment' style={{ marginBottom: isMobile ? '0px' : '15px' }}>
                <div className='info'>
                    <div className='info_'>
                        <h1 id='profile'>S</h1>
                        <h1>Sivia Df</h1>
                    </div>
                    <img src={googleIcon} alt='google'/>
                </div>

                <div className='stars'>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                    <img src={starIcon} alt='start'/>
                </div>

                <p>
                    Le llamé para que me pusiese un mueble de baño, no encajaba con el sifón, ha sido muy puntual, un profesional serio y metódico, también me ha colgado un par de estanterías, todo estupendo, lo recomiendo 100%👍🏼…
                </p>
            </div>
        </div>

        <div id='separation'/>

    </div>
  )
}

export default Comments