import React, { useEffect, useState } from 'react'
// libraries
import emailjs from '@emailjs/browser'
// css
import './contact.css'

function Contact({ color, marginTop, minHeight, marginBottom }) {

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [stateMessage, setStateMessage] = useState(null)

    useEffect(() => {
      const scrollTop = () => {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 100)
      }
      
      scrollTop()
      
      clearTimeout(scrollTop)
    }, [])

    const sendEmail = (e) => {
        e.persist()
        e.preventDefault()
        setIsSubmitting(true)
        emailjs
          .sendForm(
            'service_vfx2abh', // YOUR_SERVICE_ID
            'template_wb917xs', // YOUR_TEMPLATE_ID
            e.target,
            'bXVOf8ioB2nHxz2OW' // YOUR_PUBLIC_KEY
          )
          .then(
            (result) => {
              setStateMessage('Message sent!')
              setIsSubmitting(false)
              setTimeout(() => {
                setStateMessage(null)
              }, 5000) // hide message after 5 seconds
            },
            (error) => {
              setStateMessage('Something went wrong, please try again later')
              setIsSubmitting(false)
              setTimeout(() => {
                setStateMessage(null)
              }, 5000) // hide message after 5 seconds
            }
          )
        
        // Clears the form after sending the email
        e.target.reset()
    }

    return(
        <div className='contact' style={{ marginTop: marginTop, minHeight: minHeight, marginBottom: marginBottom }}>
            <h1 style={{ color: color, marginTop: marginTop }}>Contáctanos</h1>

            <form onSubmit={sendEmail}>
                <label style={{ color: color }}>Nombre</label>
                <input type="text" name="user_name" />
                <label style={{ color: color }}>Email</label>
                <input type="email" name="user_email" />
                <label style={{ color: color }}>Mensaje</label>
                <textarea name="message" />
                <input type="submit" value="Enviar" disabled={isSubmitting} />
                {stateMessage && <p>{stateMessage}</p>}
            </form>
        </div>
    )
}

export default Contact