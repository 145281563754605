import React from 'react'
// assets
import logoNico from '../../assets/logo.svg'
// css
import './address.css'

function Address() {
  return (
    <div className='address'>
      <iframe
        title='google' 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3034.4249752853916!2d-3.963532123360871!3d40.48798465129843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd419c765e4a28f3%3A0xc81642ff6acc1726!2sNico%20Construcciones%20y%20Reformas%20Integrales%20SL!5e0!3m2!1ses!2ses!4v1709584386549!5m2!1ses!2ses" 
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>

      <div className='address_info'>
        <h1>¿Dónde estamos?</h1>
        <img src={logoNico} alt='logo'/>
        <p>C/ Pablo Tejera, 1 Villanueva del Pardillo, Madrid</p>
      </div>

    </div>
  )
}

export default Address