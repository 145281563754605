import React from 'react'
// components
import TopBar from './components/topbar/topbar'
import Footer from './components/footer/footer'
// views
import Home from './views/home/home'
import Legal from './views/legal/legal'
import About from './views/about/about'
import Contact from './views/contact/contact'
import Projects from './views/projects/projects'
// libraries
import { Routes, Route } from 'react-router-dom'
// css
import './App.css'

function App() {
  return (
    <div className="app">
      <TopBar/>
      
      <Routes>
        <Route path='/' element={<Home/>}/>

        <Route path='/about' element={<About/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/contact' element={<Contact/>}/>

        <Route path='/legal' element={<Legal/>}/>
      </Routes>

      <Footer/>

    </div>
  )
}

export default App