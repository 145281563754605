import React from 'react'
// assets
import backgroundHeader from '../../assets/bg2.jpg'
import luxuryLogo from '../../assets/logo.svg'
import buyHouseIcon from '../../assets/icons/easy.png'
import rentHouseIcon from '../../assets/icons/customer-service.png'
import qualityIcon from '../../assets/icons/shield.png'
import priceIcon from '../../assets/icons/price-tag.png'
// css
import './experts.css'

function Experts() {

    return (
        <div
            className='experts' 
            style={{
                background: `url(${backgroundHeader})`,
                backgroundPosition: 'center !important',
                backgroundSize: 'cover !important',
                backgroundRepeat: 'no-repeat !important'
            }}
        >

            <img src={luxuryLogo} alt='logo'/>

            <p>Somos especialistas en...</p>

            <div className='experts_safe_area'>

                <div className='card'>
                    <img src={buyHouseIcon} alt='icon'/>
                    <h1>SERVICIOS</h1>
                    <p>
                        • Arquitectura <br/>
                        • Albañilería <br/>
                        • Aislamientos <br/>
                        • Puertas correderas
                    </p>
                </div>

                <div className='card'>
                    <img src={rentHouseIcon} alt='icon'/>
                    <h1>SERVICIOS</h1>
                    <p>
                        • Enfoscados <br/>
                        • Extractores <br/>
                        • Campanas <br/>
                        • Alicatados
                    </p>
                </div>

                <div className='card'>
                    <img src={qualityIcon} alt='icon'/>
                    <h1>SERVICIOS</h1>
                    <p>
                        • Tarimas flotantes <br/>
                        • Iluminación <br/>
                        • Impermeabilización <br/>
                        • Ladrillo visto
                    </p>
                </div>

                <div className='card'>
                    <img src={priceIcon} alt='icon'/>
                    <h1>SERVICIOS</h1>
                    <p>
                        • Fontaneria <br/>
                        • Carpinteria <br/>
                        • Paredes y techos de pladur <br/>
                        • Todo tipo de pinturas <br/>
                        • Alisados de paredes quitando gotele
                    </p>
                </div>

            </div>
            
            <div id='separation'/>
        </div>
    )
}

export default Experts