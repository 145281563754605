import React from 'react'
// assets
import buyHouseIcon from '../../assets/icons/easy.png'
import rentHouseIcon from '../../assets/icons/customer-service.png'
import saleHouseIcon from '../../assets/icons/diamond.png'
// css
import './options-slider.css'

function OptionsSlider() {
  return (
    <div className='options'>
      <div className='options_safe_area'>

        <div className='card'>
          <img src={buyHouseIcon} alt='icon'/>
          <h1>ASI DE SIMPLE</h1>
          <p>
            Nuestra filosofía es clara: Simplificamos lo complejo. ¿Qué implica esto? 
            Que nos esforzamos por garantizar que cada proyecto de reforma integral supere las expectativas de nuestros clientes 
            sin causarles molestias ni inconvenientes.
          </p>
        </div>

        <div className='card'>
          <img src={rentHouseIcon} alt='icon'/>
          <h1>OFRECEMOS</h1>
          <p>
            Ofrecemos a nuestros clientes un servicio integral de reformas en el que nos encargamos de todas las fases del proyecto, 
            desde el análisis inicial de las instalaciones o espacios hasta la gestión de licencias, ejecución y supervisión de la obra.
          </p>
        </div>

        <div className='card'>
          <img src={saleHouseIcon} alt='icon'/>
          <h1>COMPROMETIDOS</h1>
          <p>
            Nos comprometemos a proporcionar una experiencia sin complicaciones, asumiendo la responsabilidad de cada paso del proceso 
            para que nuestros clientes no tengan que preocuparse por los detalles y puedan disfrutar del resultado final, que reflejará 
            a la perfección su visión y necesidades.
          </p>
        </div>

      </div>
    </div>
  )
}

export default OptionsSlider