import React, { useEffect } from 'react'
//components
import ItemInfo from '../../components/item/item'
import Address from '../../components/address/address'
// assets
import nico from '../../assets/nico.jpg'
// utils
import { isMobile } from '../../utils/isMobile'
import { callMe, emailMe } from '../../utils/functions'
// css
import './about.css'

function About() {

    useEffect(() => {
        const scrollTop = () => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100)
        }
        
        scrollTop()
        
        clearTimeout(scrollTop)
    }, [])

    return (
        <div className='about'>
            <ItemInfo
                img={nico}
            >
                <h1 style={{ fontSize: isMobile ? '40px' : '60px', color: 'white' }}>Nuestro equipo</h1>
                <p style={{ color: 'white' }}>Nico Construcciones y Reformas integrales S.L se compone de un grupo de expertos altamente calificados en el campo de la remodelación, diseño, interiorismo y renovación. Bajo la dirección de D. Nico, con una vasta experiencia y una trayectoria profesional impecable, el equipo se esfuerza por ofrecer resultados excepcionales.</p>
                <p style={{ color: 'white' }}>Hoy en día, sigue avanzando en su carrera profesional brindando a nuestros clientes un servicio insuperable, combinando empatía y rigurosidad, preservando su entusiasmo con atención excepcional, calidad superior, disciplina y profesionalismo en cada uno de nuestros proyectos.</p>
                <div className='btn' onClick={ isMobile ? callMe : emailMe}>
                    <p>Contacta</p>
                </div>
            </ItemInfo>

            <Address/>
        </div>
    )
}

export default About