import React, { useState } from 'react'
// assets
import logoLuxury from '../../assets/logo.svg'
import moreIcon from '../../assets/menu.png'
// libraries
import { Link } from 'react-router-dom'
// css
import './topbar.css'

function TopBar() {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className='topbar'>
            <Link to={'/'}>
                <img src={logoLuxury} alt='logo'/>
            </Link>

            <div className='web'>
                <Link to={'/'}><p>Home</p></Link>
                <Link to={'/about'}><p>Quienes somos</p></Link>
                <Link to={'/projects'}><p>Proyectos</p></Link>
                <Link to={'/contact'}><p>Contacto</p></Link>
            </div>

            <div className='mobile'>
                <img src={moreIcon} alt='more' onClick={() => setIsOpen(!isOpen)}/>
            </div>

            {isOpen && (
                <div className='menu-mobile'>
                    <div className='menu-items'>
                        <Link id='budget-mobile' to={'/about'}>
                            <p onClick={() => setIsOpen(false)}>Quienes somos</p>
                        </Link>

                        <div style={{ height: '15px' }}/>

                        <Link id='budget-mobile' to={'/projects'}>
                            <p onClick={() => setIsOpen(false)}>Proyectos</p>
                        </Link>

                        <div style={{ height: '15px' }}/>

                        <Link id='budget-mobile' to={'/contact'}>
                            <p onClick={() => setIsOpen(false)}>Contacto</p>
                        </Link>

                        <div style={{ height: '15px' }}/>

                        <p id='budget-mobile' style={{ marginTop: '35px' }} onClick={() => setIsOpen(false)}>Cerrar</p>
                    </div>
                </div>
            )}

        </div>
    )
}

export default TopBar