import React, { useEffect, useState } from 'react'
// assets
import img1 from '../../assets/projects/1.webp'
import img2 from '../../assets/projects/2.webp'
import img3 from '../../assets/projects/3.webp'
import img4 from '../../assets/projects/4.webp'
import img5 from '../../assets/projects/5.webp'
import img6 from '../../assets/projects/6.webp'
import img7 from '../../assets/projects/7.webp'
import img8 from '../../assets/projects/8.webp'
import img9 from '../../assets/projects/9.webp'
import img10 from '../../assets/projects/10.webp'
import img11 from '../../assets/projects/11.webp'
import img12 from '../../assets/projects/12.webp'
import img13 from '../../assets/projects/13.webp'
import img14 from '../../assets/projects/14.webp'
import img15 from '../../assets/projects/15.webp'
import img16 from '../../assets/projects/16.webp'
import img17 from '../../assets/projects/17.webp'
import img18 from '../../assets/projects/18.webp'
import img19 from '../../assets/projects/19.webp'
import img20 from '../../assets/projects/20.webp'
import img21 from '../../assets/projects/21.webp'
import img22 from '../../assets/projects/22.webp'
import img23 from '../../assets/projects/23.webp'
import img24 from '../../assets/projects/24.webp'
import img25 from '../../assets/projects/25.webp'
import img26 from '../../assets/projects/26.webp'
import img27 from '../../assets/projects/27.webp'
import img28 from '../../assets/projects/28.webp'
import img29 from '../../assets/projects/29.webp'
import img30 from '../../assets/projects/30.webp'
import img31 from '../../assets/projects/31.webp'
import img32 from '../../assets/projects/32.webp'
import img33 from '../../assets/projects/33.webp'
import img34 from '../../assets/projects/34.webp'
import img35 from '../../assets/projects/35.webp'
import img36 from '../../assets/projects/36.webp'
import img37 from '../../assets/projects/37.webp'
import img38 from '../../assets/projects/38.webp'
import img39 from '../../assets/projects/39.webp'
// media
import vid1 from '../../assets/projects/vid1.mp4'
import vid2 from '../../assets/projects/vid2.mp4'
import vid3 from '../../assets/projects/vid4.mp4'
// icons
import closeIcon from '../../assets/cancel.png'
// libraries
import Fade from 'react-reveal/Fade'
// utils
import { isMobile } from '../../utils/isMobile'
// css
import './projects.css'

const slideImages = [
    {
      tag: 'img',
      url: img1
    },
    {
      tag: 'img',
      url: img2
    },
    {
      tag: 'img',
      url: img3
    },
    {
      tag: 'img',
      url: img4
    },
    {
      tag: 'img',
      url: img5
    },
    {
      tag: 'img',
      url: img6
    },
    {
      tag: 'img',
      url: img7
    },
    {
      tag: 'img',
      url: img8
    },
    {
      tag: 'img',
      url: img9
    },
    {
      tag: 'img',
      url: img10
    },
    {
      tag: 'img',
      url: img11
    },
    {
      tag: 'img',
      url: img12
    },
    {
      tag: 'img',
      url: img13
    },
    {
      tag: 'img',
      url: img14
    },
    {
      tag: 'img',
      url: img15
    },
    {
      tag: 'img',
      url: img16
    },
    {
      tag: 'img',
      url: img17
    },
    {
      tag: 'img',
      url: img18
    },
    {
      tag: 'img',
      url: img19
    },
    {
      tag: 'img',
      url: img20
    },
    {
      tag: 'img',
      url: img21
    },
    {
      tag: 'img',
      url: img22
    },
    {
      tag: 'img',
      url: img23
    },
    {
      tag: 'img',
      url: img24
    },
    {
      tag: 'img',
      url: img25
    },
    {
      tag: 'img',
      url: img26
    },
    {
      tag: 'img',
      url: img27
    },
    {
      tag: 'img',
      url: img28
    },
    {
      tag: 'img',
      url: img29
    },
    {
      tag: 'img',
      url: img30
    },
    {
      tag: 'img',
      url: img31
    },
    {
      tag: 'img',
      url: img32
    },
    {
      tag: 'img',
      url: img33
    },
    {
      tag: 'img',
      url: img34
    },
    {
      tag: 'img',
      url: img35
    },
    {
      tag: 'img',
      url: img36
    },
    {
      tag: 'img',
      url: img37
    },
    {
      tag: 'img',
      url: img38
    },
    {
      tag: 'img',
      url: img39
    },
    {
      tag: 'video',
      url: vid1
    },
    {
      tag: 'video',
      url: vid2
    },
    {
      tag: 'video',
      url: vid3
    }
]

function Projects({ state }) {

  const [preview, setPreview] = useState(null)

  useEffect(() => {
    const scrollTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
    
    scrollTop()
    
    clearTimeout(scrollTop)
  }, [])

  return (
    <div className='projects start'>
      <div className='projects-main'>

        <div className='projects_info' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h1 style={{ fontSize: isMobile ? '35px' : '45px', fontWeight: '600', marginTop: isMobile ? '0px' : '40px'}}>Hecha un vistazo...</h1>
          <video src={vid3} autoPlay muted loop style={{ width: '100%', height: '100%', margin: '5px', border: '1px solid #333333a9' }}></video>
        </div>

        <div className='projects_info'>
          <h1 style={{ fontSize: isMobile ? '35px' : '45px', fontWeight: '600', marginTop: isMobile ? '0px' : '40px'}}>...a nuestros proyectos</h1>
          <div id='separation'/>
        </div>

        {preview && (
          <div className='welcomeVideo'>
            <div className='video'>
              <div className='buttons'>
                <img src={closeIcon} alt='close' onClick={() => setPreview(null)}/>
              </div>
              <img id='preview' src={preview} alt={preview}/>
            </div>
          </div>
        )}

        <div className='grid'>
          {slideImages.map((slideImage, index)=> (
            <div key={index} style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Fade>
                {slideImage.tag === 'img' ?
                  <img
                    onClick={() => setPreview(slideImage.url)} 
                    src={slideImage.url}
                    alt='preview'
                    style={{ cursor: 'pointer' }}
                  />
                  :
                  <video src={slideImage.url} controls style={{ width: '600px', height: '400px', margin: '5px', border: '1px solid #333333a9' }}></video>
                }
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Projects