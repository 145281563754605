import React, { useEffect, useState } from 'react'
// components
import Header from '../../components/header/header'
import ItemInfo from '../../components/item/item'
import OptionsSlider from '../../components/options-slider/options-slider'
import Experts from '../../components/experts/experts'
import Comments from '../../components/comments/comments'
// views
import Contact from '../../views/contact/contact'
// utils
import { isMobile } from '../../utils/isMobile'
import { callMe, emailMe } from '../../utils/functions'
// assets
import imgSection1 from '../../assets/projects/28.webp'
import callIcon from '../../assets/call.png'
// css
import './home.css'

function Home() {

  const [isCallMe, setIsCallMe] = useState(false)

  const changeTopBarStyle = () => {
    if(window.scrollY >= 80){
      setIsCallMe(true)
    } else {
      setIsCallMe(false)
    }

    if(window.scrollY >= 4000){
      setIsCallMe(false)
    }
  }

  window.addEventListener('scroll', changeTopBarStyle)

  useEffect(() => {
    const scrollTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
    
    scrollTop()
    
    clearTimeout(scrollTop)
  }, [])

  return (
    <div className='home'>

        {isCallMe && isMobile && (
          <div id='callme' onClick={ isMobile ? callMe : emailMe}>
            <img src={callIcon} alt='call'/>
          </div>
        )}

        <Header/>
        
        <ItemInfo
          bgColor='#ffffff'
          title='¿Estás pensando en hacer una reforma integral en tu vivienda?'
          desc1='Si estás enfrentando esta situación, es muy posible que te hayas percatado de las innumerables posibilidades que Internet ofrece para la selección y contratación de una empresa especializada en remodelaciones integrales y mejoras de todo tipo de propiedades.'
          desc2='No pretendemos convencerte de que somos la mejor opción en reformas integrales, pero nos encantaría escuchar tus ideas y proporcionarte un presupuesto adaptado a tus necesidades, sin compromiso alguno.'
          imgLeft={imgSection1}
        />

        <div id='line'/>

        <OptionsSlider/>

        <Experts/>

        <Comments/>

        <div className='contact-mobile'>
          <Contact color={'#000000'} marginTop={'0px'} minHeight={'100%'} marginBottom={'50px'}/>
        </div>
    </div>
  )
}

export default Home