import React from 'react'
// assets
import instagramIcon from '../../assets/socials/instagram.png'
import emailIcon from '../../assets/socials/email.png'
import phoneIcon from '../../assets/socials/phone.png'
// utils
import { isMobile } from '../../utils/isMobile'
import { instagram } from '../../utils/functions'
// libraries
import { Link } from 'react-router-dom'
// css
import './footer.css'

const year = new Date().getFullYear()

function Footer() {

  return (
    <div className='footer'>
      <div id='separation'/>

      <div className='socials'>
        <div className='social-item' onClick={() => window.open(instagram)}>
          <img style={{ width: isMobile ? '30px' : '40px', height: isMobile ? '30px' : '40px' }} src={instagramIcon} alt='instagram'/>
          <p>Instagram / @nico_construcionessl</p>
        </div>

        <div className='social-item'>
          <img style={{ width: isMobile ? '30px' : '40px', height: isMobile ? '30px' : '40px' }} src={emailIcon} alt='instagram' onClick={() => window.open(instagram)}/>
          <p>E-mail / nico.reformas.integrales@gmail.com</p>
        </div>

        <div className='social-item'>
          <img style={{ width: isMobile ? '30px' : '40px', height: isMobile ? '30px' : '40px' }} src={phoneIcon} alt='instagram' onClick={() => window.open(instagram)}/>
          <p>Telefono / +34 680 49 07 55</p>
        </div>
      </div>

      <div id='line'/>

      <div id='separation'/>

      <div className='footer-section'>
        <p style={{ textDecoration: 'none' }}>Nico Construcciones | Reformas Integrales S.L © {year + 1}</p>
        <div className='footer-section-link'>
          <Link to={'/legal'}><p>Aviso Legal</p></Link>
          <Link to={'/about'}><p>Sobre Nosotros</p></Link>
        </div>
      </div>

      <div id='separation'/>
    </div>
  )
}

export default Footer