import React from 'react'
// css
import './item.css'

function ItemInfo({ title, desc1, desc2, desc3, img, imgLeft, btnText, children, onClick, bgColor }) {
  return (
    <>
        <div className='item' style={{ background: bgColor }}>
          <div className='card'>
              {img && <img
                style={{
                      marginTop: '50px'
                    }}
                    src={img} alt='preview'
                  />
                }

                <div className='item-info'>
                  <h1>{title}</h1>

                  {desc1 && <p>{desc1}</p>}
                  {desc2 && <p>{desc2}</p>}
                  {desc3 && <p>{desc3}</p>}

                  {children && children}

                  {btnText && (
                    <div className='btn' onClick={onClick}>
                      <p>{btnText}</p>
                    </div>
                  )}
              </div>

              {imgLeft && <img src={imgLeft} alt='preview'/>}
            </div>
        </div>
    </>
  )
}

export default ItemInfo